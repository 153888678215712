import React, { useContext } from 'react';
import { EventPackageContext } from 'components/TourEventPackagesTable/EventPackagePopup/constants';

export default function Raw(props) {
	const { rawEventPackageInfo = {} } = useContext(EventPackageContext);

	return (
		<pre className="codeblock">
			<code>
				{JSON.stringify(rawEventPackageInfo, null, 3)}
			</code>
		</pre>
	);
};
