import React, { useContext } from 'react';
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import { EventPackageContext } from 'components/TourEventPackagesTable/EventPackagePopup/constants';
import { currencyFormat } from 'util/numbers';

export default function SuggestedPriceHistory(props) {
	return (
		<div
			className="SuggestedPriceHistory w-100"
		>
			<SuggestedPriceHistoryTable />
		</div>
	);
};

const SuggestedPriceHistoryTable = (props) => {
	const { rawEventPackageInfo } = useContext(EventPackageContext);
	const { suggestedPriceHistory = [] } = rawEventPackageInfo;

	return suggestedPriceHistory.length > 0 ? (
		<>
			<p className="h6 my-1.5">Showing the last 50 results (max)</p>
			<div className="border rounded-sm scroll-x">
				<Table size="sm" className="SuggestedPriceHistoryTable responsive table-stripe">
					<thead>
						<tr>
							<th>DATE</th>
							<th>PRICE</th>
						</tr>
					</thead>
					<tbody>
						{suggestedPriceHistory.map(t => (
							<SuggestedPriceHistoryRow
								key={t.date.toString()}
								{...t}
							/>
						))}
					</tbody>
				</Table>
			</div>
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No ticket price history</span>
		</Flex>
	);
};

const SuggestedPriceHistoryRow = React.memo((props) => {
	const {
		price,
		date,
	} = props;

	return (
		<tr
			className="SuggestedPriceHistoryRow"
		>
			{/* Date */}
			<td title={date.toString()}>
				<span className="text-gray-600">{dayjs(date).format('M/DD/YY h:mm:ss a')}</span>
			</td>
			{/* Price */}
			<td title={currencyFormat(price, '-')}>
				<span>{currencyFormat(price, '-')}</span>
			</td>
		</tr>
	);
});
