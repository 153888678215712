import React, { useContext } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import useGetEventPackageInfo from 'components/TourEventPackagesTable/hooks/useGetEventPackageInfo';
import { EventPackageContext } from 'components/TourEventPackagesTable/EventPackagePopup/constants';
import { currencyFormat } from 'util/numbers';

export default function General(props) {
	const { rawEventPackageInfo = {} } = useContext(EventPackageContext);

	const {
		id,
		name,
		type,
		packageType,
		managementFee,
		ticketGroupId,
		minPackagePrice,
		safeMktPrice,
		realMktPrice,
		suggestedPrice,
		posTicketPrice,
		isPosPriceUnmatched,
		packageComponentsValue,
		posTicket: {
			isBroadcast,
		} = {},
	} = useGetEventPackageInfo(rawEventPackageInfo);

	const Type = () => (
		<div className="text-capitalize">
			<span>{type || '-'}</span>
			<SlashDivider />
			<span className="text-gray-600">{packageType || '-'}</span>
		</div>
	);

	const MktPrice = () => (
		<div>
			<span>{currencyFormat(safeMktPrice, '-')}</span>
			<SlashDivider />
			<span
				className={classnames({
					'text-gray-600': !safeMktPrice || !realMktPrice || safeMktPrice === realMktPrice,
					'text-warning': safeMktPrice && realMktPrice && safeMktPrice !== realMktPrice,
				})}
			>
				{currencyFormat(realMktPrice, '-')}
			</span>
		</div>
	);

	const items = [{
		label: 'Event Package ID',
		value: id,
	}, {
		label: 'Name',
		value: name,
	}, {
		label: 'Type',
		value: <Type />,
	}, {
		label: 'Ticket Group ID',
		value: (ticketGroupId || <FontAwesomeIcon icon={faCircleXmark} className="text-danger"/>),
	}, {
		label: 'Broadcast',
		value: !!ticketGroupId ? (
			<FontAwesomeIcon
				icon={isBroadcast ? faCircleCheck : faCircleXmark}
				className={isBroadcast ? 'text-success' : 'text-danger'}
			/>
		) : (
			<span className="text-gray-600">No TGID</span>
		),
	}, {
		label: 'Suggested Price',
		value: currencyFormat(suggestedPrice, '-'),
	}, {
		label: 'Current Tix Price in POS',
		value: (
			<span
				className={classnames({
					'text-warning': isPosPriceUnmatched,
				})}
			>
				{currencyFormat(posTicketPrice, '-')}
			</span>
		),
	}, {
		label: 'Market Safe Price / Real Price',
		value: <MktPrice />,
	}, {
		label: 'Package Components Value',
		value: currencyFormat(packageComponentsValue, '-'),
	}, {
		label: 'Management Fee',
		value: managementFee ? `${managementFee}%` : '-',
	}, {
		label: 'Min Package Price',
		value: currencyFormat(minPackagePrice, '-'),
	}];

	return (
		<Flex direction="column" className="General border rounded-sm py-2 px-3 bg-gray-100">
			{items.map((item) => (
				<ItemWrapper
					key={item.label}
					{...item}
				/>
			))}
		</Flex>
	);
};

const ItemWrapper = ({ label, value }) => (
	<Flex className="line-item-wrapper">
		<Flex className="item-wrapper">
			<span className="label">{label}</span>
			<span className="value">{value}</span>
		</Flex>
	</Flex>
);
