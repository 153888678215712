import React, { useContext } from 'react';
import classnames from 'classnames';
import sumBy from 'lodash/sumBy';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
import SlashDivider from 'components/SlashDivider';
import SpinnerCenter from 'components/SpinnerCenter';
import { EventPackageContext } from 'components/TourEventPackagesTable/EventPackagePopup/constants';
import { commaFormat, currencyFormat } from 'util/numbers';

export default function MktTix(props) {
	const { mktTixIsLoading, mktTixIsFetching, tix, filters, calculatedTicketPrice, mktTixRefetch, rawEventPackageInfo } = useContext(EventPackageContext);
	const currentRealMktPrice = rawEventPackageInfo?.ticketPriceHistory?.[0]?.realPrice;

	return mktTixIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<div
			className="MktTix w-100"
		>
			<Flex justify="between" align="center" className="mb-2.5">
				<Flex direction="column">
					<p className="h6 mt-1.5 mb-0 text-gray-700">New REAL price / Current REAL price</p>
					<Flex>
						<span className="f-rem-0.95 fw5">{currencyFormat(calculatedTicketPrice, '-')}</span>
						<SlashDivider />
						<span
							className={classnames('f-rem-0.95 fw5', {
								'text-warning': !!calculatedTicketPrice && calculatedTicketPrice !== currentRealMktPrice,
							})}
						>
							{currencyFormat(currentRealMktPrice, '-')}
						</span>
					</Flex>
				</Flex>
				<RefreshBtn
					isLoading={mktTixIsFetching}
					onClick={() => !mktTixIsFetching && mktTixRefetch()}
					style={{
						height: '28px',
					}}
				/>
			</Flex>

			<p className="h6 my-1.5 text-gray-700">Tickets ({commaFormat(tix?.length, undefined, '0')} listings, {commaFormat(sumBy(tix, 'quantity'), undefined, '-')} tix)</p>
			<div className="border rounded-sm scroll-x">
				<MktTixTable />
			</div>

			<p className="h6 mt-3 mb-1.5 text-gray-700">Filter info</p>
			<pre className="codeblock">
				<code>
					{JSON.stringify(filters, null, 3)}
				</code>
			</pre>

			<p className="h6 my-1.5 text-warning">NOTE</p>
			<span>When this popup is openend or the refresh button is hit, the latest market data is pulled. The calculated ticket price displayed here is based off of this latest market data, and so it may not match the MKT REAL $ value in the table. If they don't match, the MKT REAL $ value here will be colored <span className="text-warning">orange.</span></span>
		</div>
	);
};

const MktTixTable = () => {
	const { tix } = useContext(EventPackageContext);

	return tix.length > 0 ? (
		<Table size="sm" className="MktTixTable responsive table-stripe">
			<thead>
				<tr>
					<th>EXCH ID</th>
					<th>SEC</th>
					<th>ROW</th>
					<th>SEATS L/H</th>
					<th>AVAIL</th>
					<th>SPLITS</th>
					<th>PRICE</th>
					<th>MKT</th>
					<th>NOTES</th>
				</tr>
			</thead>
			<tbody>
				{tix.map(t => (
					<MktTixRow
						key={t.exchTgId}
						{...t}
					/>
				))}
			</tbody>
		</Table>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No mkt tix found</span>
		</Flex>
	);
};

const MktTixRow = React.memo((props) => {
	const {
		exchTgId,
		high_seat,
		low_seat,
		section,
		row,
		quantity,
		valid_splits = [],
		price,
		notes,
		marketplace,
	} = props;

	const Seats = () => (
		<>
			<span>{low_seat || '-'}</span>
			<SlashDivider />
			<span>{high_seat || '-'}</span>
		</>
	);

	return (
		<tr
			className="MktTixRow"
		>
			{/* Exchange ID */}
			<td title={exchTgId}>
				<span className="text-gray-600">{exchTgId}</span>
			</td>
			{/* Section */}
			<td title={section}>
				<span className="fw5">{section}</span>
			</td>
			{/* Row */}
			<td title={row}>
				<span className="fw5">{row}</span>
			</td>
			{/* Seats */}
			<td title={`${low_seat || '-'}/${high_seat || '-'}`}>
				<Seats />
			</td>
			{/* Available */}
			<td title={commaFormat(quantity, undefined, '-')}>
				<span>{commaFormat(quantity, undefined, '-')}</span>
			</td>
			{/* Splits */}
			<td title={valid_splits?.toString?.() || '-'}>
				<span>{valid_splits?.toString?.() || '-'}</span>
			</td>
			{/* Price */}
			<td title={currencyFormat(price, '-')}>
				<span>{currencyFormat(price, '-')}</span>
			</td>
			{/* Marketplace */}
			<td title={marketplace || '-'}>
				<span className={`text-capitalize ${marketplace}`.trim()}>{marketplace || '-'}</span>
			</td>
			{/* Notes */}
			<td title={notes || '-'}>
				<span>{notes || '-'}</span>
			</td>
		</tr>
	);
});
